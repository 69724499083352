import React from 'react'; // { useState, useCallback }
import PropTypes from 'prop-types';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import style from './OfficeDetail.module.scss';

import { SmartLink } from '../SmartLink';
import RecentInvestment from '../RecentInvestment';

const OfficeDetail = props => {
    const {
        name,
        subTitle,
        // region,
        address,
        tel,
        fax,
        description,
        map: mapCoordinates,
        team,
        investments,
        googleMapKey,
    } = props;

    const center = mapCoordinates;

    // const [map, setMap] = useState(null);

    // const handleBoundsChanged = () => {
    //     map.setCenter(center);
    // };

    const containerStyle = {
        width: '100%',
        height: '719px',
    };

    // const onLoad = useCallback(function callback (map) {
    //     const bounds = new window.google.maps.LatLngBounds();
    //     map.fitBounds(bounds);
    //     setMap(map);
    // }, []);

    // const onUnmount = useCallback(function callback (map) {
    //     setMap(null);
    // }, []);

    // scrroll to top
    // const [visible, setVisible] = useState(false)

    // const toggleVisible = () => {
    //   const scrolled = document.getElementById('modalpage').scrollTop;
    //   if (scrolled > 100) {
    //     setVisible(true)
    //   } else if (scrolled <= 300) {
    //     setVisible(false)
    //   }
    // };
    // const scrollToTop = () => {
    //     window.scrollTo({
    //       top: 0,
    //       behavior: 'smooth'
    //       /* you can also use 'auto' behaviour
    //          in place of 'smooth' */
    //     });
    //   };
    //   window.addEventListener('scroll', toggleVisible);

    return (
        <div className={style.el}>
            <div className={style.addressContainer}>
                <div className={style.addressWrapper}>
                    <div className={style.addressDetails}>
                        <div className={[style.container, 'container'].join(' ')}>
                            <div className={style.addressGroup}>
                                <h2 className={style.addressName}>{name}</h2>
                                <h2 className={style.subTitle}>{subTitle}</h2>
                            </div>
                            <div className={style.addressContact}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: address,
                                    }}
                                />
                                {tel && <div className={style.tel}>
                                    Tel: <a href={`tel:${tel}`}>{tel}</a>
                                </div>}
                                {fax && <div>Fax: {fax}</div>}
                            </div>
                            <div className={style.addressDesc}>
                                <h4>{description}</h4>
                            </div>
                        </div>
                    </div>
                    <div className={style.addressMap}>
                        <LoadScript googleMapsApiKey={`${googleMapKey}`}>
                            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16}>
                                <Marker position={center} />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                </div>
            </div>
            {team.members.length > 0 && <section className={style.teamWrapper}>
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.teamName}>
                        <h2>{team.title}</h2>
                    </div>
                    <div className={style.peopleCardWrapper}>
                        {team.members.map(({ name, image, title, link }, id) => {
                            const imageUrl = image?.url !== '' ? image.url : '/images/default.png';
                            return (
                                <SmartLink href={link.url} className={style.peopleCard} key={id}>
                                    <div className={style.image}>
                                        <div className={style.imageWrap}>
                                            <img src={imageUrl} alt={image.alt} />
                                        </div>
                                    </div>
                                    <div className={style.content}>
                                        <h4 className={style.name}>{name}</h4>
                                        <p className={style.title}>{title}</p>
                                    </div>
                                </SmartLink>
                            );
                        })}
                    </div>
                    <div className={style.meetLinkCta}>
                        <div className={style.meetLink}>
                            <SmartLink className='arrow-link' hasArrow href={team.cta.link.url}>
                                {team.cta.text}
                            </SmartLink>
                        </div>
                    </div>
                </div>
            </section>}

            <RecentInvestment {...investments} />
            {/* <div>
                <div onClick={scrollToTop} className={style.loader}
                    style={{ display: visible ? 'inline' : 'none' }}>scroll to top</div>
            </div> */}
        </div>
    );
};

OfficeDetail.propTypes = {
    name: PropTypes.string,
    subTitle: PropTypes.string,
    region: PropTypes.string,
    address: PropTypes.string,
    tel: PropTypes.string,
    fax: PropTypes.string,
    description: PropTypes.string,
    map: PropTypes.shape({
        lng: PropTypes.number,
        lat: PropTypes.number,
    }),
    team: PropTypes.object,
    investments: PropTypes.object,

    title: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    mobileImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    contents: PropTypes.any,
    googleMapKey: PropTypes.string,
};
export default OfficeDetail;
