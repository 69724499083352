import React from 'react';
import PropTypes from 'prop-types';
import style from './GovernanceListing.module.scss';
import cx from 'classnames';

const GovernanceListing = props => {
    const { title, menu, contents } = props;

    return (
        <section className={style.section}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.section__row}>
                    <div className={style.section__col}>
                        {title && <h1>{title}</h1>}
                        <ul className={cx(style.section__list, style.sticky)} id='section__list'>
                            {menu.map((item, i) => {
                                const listItemClickHandler = e => {
                                    e.preventDefault();

                                    const activeListItem = document.querySelector(`#section__list a.${style.active}`);

                                    if (activeListItem !== null) {
                                        activeListItem.classList.remove(style.active);
                                    }

                                    e.target.className = style.active;

                                    const ref = e.currentTarget.getAttribute('href');
                                    const targetElement = document.getElementById(ref);

                                    targetElement.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'start',
                                    });
                                };

                                return (
                                    <li className={style['section__list-item']} key={i}>
                                        <a
                                            href={`tab-${item.id}`}
                                            onClick={listItemClickHandler}
                                            className={i === 0 ? style.active : ''}
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    <div className={style.section__col}>
                        {contents.map((item, j) => {
                            let markup;

                            if (item.type === 'staticPageContentLinebreak') {
                                markup = <div className={style.section__linebreak} key={j} />;
                            }

                            if (item.type === 'staticPageContentAnchor') {
                                markup = (
                                    <div
                                        className={[style.section__title, item.indent ? style.indent : ''].join(' ')}
                                        id={`tab-${item.id}`}
                                        key={j}
                                    >
                                        {item.title}
                                    </div>
                                );
                            }

                            if (item.type === 'staticPageContentTextContent') {
                                markup = (
                                    <>
                                        {item.title && item.id && item.title.length > 0 && item.id.length > 0 && (
                                            <div
                                                className={[style.section__title, item.indent ? style.indent : ''].join(
                                                    ' '
                                                )}
                                                id={`tab-${item.id}`}
                                                key={j}
                                            >
                                                {item.title}
                                            </div>
                                        )}
                                        <div
                                            className={[style.section__desc, item.indent ? style.indent : ''].join(' ')}
                                            dangerouslySetInnerHTML={{
                                                __html: item.body,
                                            }}
                                            key={j}
                                        />
                                    </>
                                );
                            }

                            return markup;
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

GovernanceListing.propTypes = {
    title: PropTypes.string,
    menu: PropTypes.array,
    contents: PropTypes.array,
};

export default GovernanceListing;
