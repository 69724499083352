import React from 'react';
import PropTypes from 'prop-types';
import style from './ScrollHijackGroupBanner.module.scss';
import { useMediaQuery } from '@react-hook/media-query';

const ScrollHijackGroupBanner = ({ index, image, mobileImage }) => {
    const isMobile = useMediaQuery('only screen and (max-width: 650px)');
    const useMobileImage = isMobile && mobileImage?.url.length > 1 ? mobileImage.url : image?.url;
    return (
        <div
            key={index}
            className={style.image}
        >
            <img src={useMobileImage} />
        </div>
    );
};

ScrollHijackGroupBanner.propTypes = {
    index: PropTypes.number,
    mobileImage: PropTypes.object,
    image: PropTypes.object,
};
export default ScrollHijackGroupBanner;
