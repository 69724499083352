import React, { useEffect, useState } from 'react';
import style from './HomeTeasers.module.scss';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import TeaserCard from '../TeaserCard';

const HomeTeasers = ({ items, title }) => {
    const [isOdd, setIsOdd] = useState(false);
    const isMobile = useMediaQuery('only screen and (max-width: 650px)');
    const gridLayout = [
        '1 / 1 / 3 / 2', //
        '3 / 1 / 5 / 2',
        '1 / 2 / 2 / 3',
        '2 / 2 / 3 / 3',
        '3 / 2 / 4 / 3',
    ];

    useEffect(() => {
        let odd = false;
        if (items.length % 2 !== 0) {
            setIsOdd(true);
            items.forEach((item) => {
                if (item.theme === 'Large') {
                    odd = true;
                }
            });
            gridLayout.forEach((layout, index) => {
                const teaserCard = document.getElementById(`teaser-card-${index}`);
                if (teaserCard) {
                    teaserCard.style.gridArea = layout;
                }
            });
        }
        setIsOdd(odd);
    }, [items]);

    return (
        <div className={[style.container, 'container'].join(' ')}>
            {title && (
                <h3 className={style.title}>{title}</h3>
            )}
            <div className={[style.teaserWrapper, isOdd ? style.oddLayout : style.evenLayout].join(' ')}>
                {items.length &&
                    items.map((item, index) => (
                        <TeaserCard
                            className={style.teaserCard}
                            {...item}
                            key={index}
                            index={index}
                            isMobile={isMobile}
                            itemsLength={items.length}
                            isOdd={isOdd}
                        />
                    ))}
            </div>
        </div>
    );
};

HomeTeasers.propTypes = {
    type: PropTypes.string,
    items: PropTypes.array,
    title: PropTypes.string,
};

export default HomeTeasers;
