import React from 'react';
import PropTypes from 'prop-types';

import style from './RichTextContents.module.scss';

const RichTextContents = ({ title, contents }) => {
    return (
        <div className={style.el}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.wrapper}>
                    <h1 className={style.title}>{title}</h1>
                </div>
                <div className={style.wrapper}>
                    <div className={style.content}>
                        {contents &&
                            contents.map((item, i) => {
                                if (item.type === 'staticPageContentLinebreak') {
                                    return <div key={i} className={style.separator} />;
                                } else {
                                    return (
                                        <div key={i} className={style.contentItem}>
                                            {item.title && <h4 className={style.heading}>{item.title}</h4>}
                                            {item.body && (
                                                <div
                                                    className={style.bodyText}
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.body,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    );
                                }
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

RichTextContents.propTypes = {
    title: PropTypes.string,
    contents: PropTypes.array,
};
export default RichTextContents;

RichTextContents.defaultProps = {};
