import React from 'react';
import PropTypes from 'prop-types';

import style from './ScrollHijackGroup.module.scss';
import ReportCard from '../Cards/reportCard';
import ScrollHijackGroupBanner from '../ScrollHijackGroupBanner';
import ScrollHijackGroupStatementsAndStats from '../ScrollHijackGroupStatementsAndStats';
import ScrollHijackGroupTextBlockItem from '../ScrollHijackGroupTextBlockItem';

const ScrollHijackGroup = ({ data, title }) => {
    const content = data.map((item, index) => {
        if (item.type === 'textBlockItem') {
            const { description, cta } = item;
            return (
                <ScrollHijackGroupTextBlockItem index={index} description={description} cta={cta} />
            );
        } else if (item.type === 'downloadReport') {
            return (
                <ReportCard key={index} {...item} />
            );
        } else if (item.type === 'banner') {
            const { image, mobileImage } = item;
            return (
                <ScrollHijackGroupBanner index={index} image={image} mobileImage={mobileImage}/>
            );
        } else if (item.type === 'statementsAndStats') {
            const { statements, title, stats } = item;
            if (!statements) {
                return '';
            }
            return (
                <ScrollHijackGroupStatementsAndStats index={index} statements={statements} title={title} stats={stats}/>
            )
        }

        return '';
    });

    return (
        <div className={[style.el, 'two-cols-details-box'].join(' ')}>
            <div className={style.innerHeader}>
                <div className={style.content}>
                    <div className={[style.container, 'container'].join(' ')}>
                        <div className={style.contentWrapper}>
                            <h2 className={style.title} dangerouslySetInnerHTML={{ __html: title }} />
                        </div>
                    </div>
                </div>
                <div className={style.scrollGroup}>
                    {content}
                </div>
            </div>
        </div>
    );
};

ScrollHijackGroup.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    cta: PropTypes.object,
};
export default ScrollHijackGroup;
