import React from 'react';
import PropTypes from 'prop-types';

import GovernanceListing from '../GovernanceListing';
import RichTextContents from '../RichTextContents';

// import style from './StaticPageContent.module.scss';

const StaticPageContent = props => {
    const { menu, withAnchorMenu } = props;

    if (menu?.length > 0 && withAnchorMenu) {
        return <GovernanceListing {...props} />;
    }

    return <RichTextContents {...props} />;
};

StaticPageContent.propTypes = {
    withAnchorMenu: PropTypes.bool,
    title: PropTypes.string,
    menu: PropTypes.array,
    contents: PropTypes.array,
};

export default StaticPageContent;
