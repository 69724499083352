import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { SmartLink } from '../SmartLink';

import style from './NewsCard.module.scss';

const NewsCard = ({ author, title, link, filters, image, publishingDate, articleType, cta, type }) => {
    const filterNames = filters
        ?.map(type => type.value.map(value => [type.type, value.value].join('-')).join(' '))
        .join(' ');
    const router = useRouter();

    const pagePath = link.url.replace(/\/$/, '');
    const cardLink = `${router.asPath}?pageId=${pagePath}`;

    const ctaPagePath = cta.link.url.replace(/\/$/, '');
    const ctaLink = `${router.asPath}?pageId=${ctaPagePath}`;

    const hasImage = image && image.url !== '';

    return (
        <SmartLink href={cardLink} as={link.url} className={[style.el, filterNames].join(' ')}>
            <div className={[style.content, hasImage ? '' : style.noImage].join(' ')}>
                {hasImage && (
                    <div className={style.wrapper}>
                        <div className={style.image}>
                            <img src={image?.url} alt={image?.alt} />
                        </div>
                    </div>
                )}
                {/* {image && (
                    <div className={style.wrapper}>
                        <div className={style.image}>
                            <div className={style.imageWrap}>
                                <img src={image.url} alt={image.alt} />
                            </div>
                        </div>
                    </div>
                )} */}
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.type}>{type}</div>
                        <div className={style.date}>{publishingDate}</div>
                    </div>
                    <h4 className={style.title}>{title}</h4>
                    <div className={style.button}>
                        <SmartLink href={ctaLink} as={cta.link.url}>
                            {cta.text}
                        </SmartLink>
                    </div>
                </div>
            </div>
        </SmartLink>
    );
};

NewsCard.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.object,
    filters: PropTypes.array,
    selectedFilters: PropTypes.array,
    image: PropTypes.object,
    author: PropTypes.array,
    publishingDate: PropTypes.string,
    articleType: PropTypes.string,
    cta: PropTypes.object,
    type: PropTypes.string,
};

export default NewsCard;
