import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import style from './TwoColumnBanner.module.scss';
import { isArray } from 'lodash';
import { SmartLink } from '../SmartLink';

const TwoColumnBanner = (props) => {
    const { title, description, image, mobileImage, diagonal } = props;
    let { cta } = props;

    const isMobile = useMediaQuery('only screen and (max-width: 650px)');
    if (!isArray(cta)) {
        cta = [cta];
    }

    const baseClass = [style.el];
    if (diagonal) {
        baseClass.push(style.diagonal);
    }

    return (
        <div className={baseClass.join(' ')}>
            <div className={style.container}>
                <div className={style.bannerLeft}>
                    <div className={style.heroBannerWrapper}>
                        <div className={style.heroBannerContainer}>
                            <div className={style.bannerTop}>
                                <div className={[style.container, 'container'].join(' ')}>
                                    <h1>{title}</h1>
                                </div>
                            </div>
                            <div className={[style.container, style.bannerBottom, 'container'].join(' ')}>
                                {description && (
                                    <div
                                        className={style.bannerDesc}
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                )}
                                {cta && cta.map((c, i) => (
                                    c.link && c.text && <div className={style.cta} key={i}>
                                        <div className={style.ctaLink}>
                                            <SmartLink href={c.link?.url} hasArrow className='arrow-link'>
                                                {c.text}
                                            </SmartLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* <div className={style.bannerDesc}><p>lorem ipsum</p></div> */}
                </div>
                <div className={style.bannerRight}>
                    <div className={style.bannerWrapper}>
                        <div
                            className={style.staticBanner}
                            style={{
                                backgroundImage: `url(${isMobile && mobileImage.url ? mobileImage.url : image.url})`,
                                backgroundSize: 'cover',
                            }}
                        >
                            {' '}
                        </div>
                    </div>
                    {/* <div className={style.overlay}>
                        <OverlayImage />
                    </div> */}
                </div>
                {/* caption */}
            </div>
        </div>
    );
};

TwoColumnBanner.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.any,
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    mobileImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    diagonal: PropTypes.bool,
};
export default TwoColumnBanner;
