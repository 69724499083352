import React from 'react';
import PropTypes from 'prop-types';
// import RelatedNews from '../RelatedNews';
// import StrategyTeam from './StrategyTeam';
// import RecentInvestment from '../RecentInvestment';
import style from './InvestmentDetails.module.scss';

const InvestmentDetails = props => {
    const { description, title, facts, icons } = props; // Investment type
    // const { title: stitle, items: sitems, cta: scta } = contents[1]; // strategy team
    // const { title: rtitle, items: ritems, cta: rcta } = contents[2]; // Recent Investments
    // const { title, items } = contents[3]; // Related News
    return (
        <>
            <div className={style.el}>
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.creditTypeWrapper}>
                        <div className={style.title}>
                            <h2>{title}</h2>
                        </div>
                        <div className={style.desc}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        </div>
                        <div className={style.iconItemWrapper}>
                            {icons.map(({ text, image }, id) => (
                                <div key={id} className={style.iconItem}>
                                    <img src={image.url} alt={image.alt} />
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: text,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className={style.facts}>
                            {facts.map(({ title, description }, id) => (
                                <div className={style.factsInfo} key={id}>
                                    <h3 className={style.factsTitle}>{title}</h3>
                                    <div
                                        className={style.factsDesc}
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* <StrategyTeam title={stitle} items={sitems} cta={scta} />
            <RecentInvestment title={rtitle} items={ritems} cta={rcta} />
            <div className={style.relatedNewsWrapper}>
                <RelatedNews title={title} items={items} />
            </div> */}
        </>
    );
};

InvestmentDetails.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    icons: PropTypes.array,
    facts: PropTypes.array,
};
export default InvestmentDetails;

InvestmentDetails.defaultProps = {};
