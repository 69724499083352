import React from 'react';
import PropTypes from 'prop-types';

import ReportCard from '../Cards/reportCard';

import style from './InvResReport.module.scss';

const InvResReport = props => {
    const { title, description, report } = props;

    return (
        <div className={[style.el, 'inv-res-report'].join(' ')}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.content}>
                    <h2 className={style.title}>{title}</h2>
                    <div className={style.right}>
                        <div className={style.description} dangerouslySetInnerHTML={{ __html: description }} />
                        {report && (
                            <div className={style.reportCard}>
                                <ReportCard {...report} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

InvResReport.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    report: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.object,
        mobileImage: PropTypes.object,
        publishingDate: PropTypes.string,
        cta: PropTypes.object,
    }),
};

export default InvResReport;
