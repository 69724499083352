import React from 'react';
import PropTypes from 'prop-types';
import style from './RecentInvestment.module.scss';
import { SmartLink } from '../SmartLink';
import { useRouter } from 'next/router';

import PortfolioCard from '../Cards/portfolioCard';

const RecentInvestment = props => {
    const { title, items, cta } = props;
    const router = useRouter();
    const isPeople = router.asPath.indexOf('people') >= 0;

    return (
        <>{items.length > 0 && <div
            className={[style.el, 'recent-investments', isPeople ? style.people : null].join(' ')}
            style={cta.link.url.length <= 0 ? { borderBottom: '1px solid #C9C9D7' } : {}}
        >
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.content}>
                    <h2 className={style.title}>{title}</h2>
                    <div className={style.items}>
                        {items &&
                            items
                                .filter((_item, i) => i < 8)
                                .map(({ icon, name, link, country, copies }, id) => (
                                    <div className={style.image} key={id}>
                                        <PortfolioCard
                                            name={name}
                                            description={country ? copies.countryLabel + ' ' + country : ''}
                                            icon={icon}
                                            cta={{ link: link, text: copies.findOutMore }}
                                        />
                                    </div>
                                ))}
                    </div>
                </div>
            </div>
            {cta.link.url ? (
                <div className={style.cta}>
                    <div className='container'>
                        <div className={style.ctaLink}>
                            <SmartLink href={cta.link.url} hasArrow className='arrow-link'>
                                {cta.text}
                            </SmartLink>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>}
        </>
    );
};

RecentInvestment.propTypes = {
    title: PropTypes.any,
    items: PropTypes.array,
    cta: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.object,
    }),
};
export default RecentInvestment;

RecentInvestment.defaultProps = {};
