import React from 'react';
import { PropTypes } from 'prop-types';
import { useRouter } from 'next/router';

import { SmartLink } from '../SmartLink';
import style from './TwoColsDetailsBox.module.scss';
// import ReactModal from 'react-modal';

const TwoColsDetailsBox = props => {
    const { popup, title, titleColor, description, facts, cta } = props;
    let { items } = props;
    const router = useRouter();
    if (!items) {
        items = [{ title, description, facts, cta }];
    }

    return (
        <div className={[style.el, 'two-cols-details-box', titleColor].join(' ')}>
            <div className={style.wrapper}>
                {items.map(({ title, description, facts, cta }, id) => {
                    const pagePath = cta.link.url.replace(/\/$/, '');
                    const link = popup ? `${router.asPath}?pageId=${pagePath}` : cta.link.url;
                    return (
                        <div key={id} className={style.innerWrapper}>
                            <div className={[style.container, 'container'].join(' ')}>
                                <div className={style.itemLeft}>
                                    <h2>{title}</h2>
                                </div>
                                <div className={[style.itemRight, 'details'].join(' ')}>
                                    <div className={style.itemDetails}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: description,
                                            }}
                                        />
                                    </div>
                                    {facts && <div className={style.itemFacts}>
                                        {facts.map(({ title, description }, idf) => (
                                            <div className={style.statsInfo} key={idf}>
                                                <div className={style.statsTitle}>{title}</div>
                                                <div
                                                    className={style.statsDesc}
                                                    dangerouslySetInnerHTML={{
                                                        __html: description,
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>}
                                    {cta.link.url !== '' && (
                                        <div className={style.cta}>
                                            <div className={style.ctaLink}>
                                                {popup ? (
                                                    <SmartLink
                                                        href={link}
                                                        hasArrow
                                                        className='arrow-link'
                                                        as={cta.link.url}
                                                    >
                                                        {cta.text}
                                                    </SmartLink>
                                                ) : (
                                                    <SmartLink href={link} hasArrow className='arrow-link'>
                                                        {cta.text}
                                                    </SmartLink>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
TwoColsDetailsBox.propTypes = {
    items: PropTypes.array,
    contents: PropTypes.array,
    popup: PropTypes.bool,
    title: PropTypes.string,
    titleColor: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.object,
    facts: PropTypes.array,
};
export default TwoColsDetailsBox;
