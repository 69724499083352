import React from 'react';
import PropTypes from 'prop-types';

import style from './ScrollHijackGroupTextBlockItem.module.scss';
import { SmartLink } from '../SmartLink';

const ScrollHijackGroupTextBlockItem = ({ index, description, cta }) => {
    return (
        <div
            key={index}
            className={style.textBlock}
        >
            <div
                className={[style.description, 'details'].join(' ')}
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            />
            {cta?.link?.url !== '' && (
                <div className={style.cta}>
                    <div className={style.ctaLink}>
                        <SmartLink href={cta.link.url} hasArrow className='arrow-link'>
                            {cta.text}
                        </SmartLink>
                    </div>
                </div>
            )}
        </div>
    );
};

ScrollHijackGroupTextBlockItem.propTypes = {
    index: PropTypes.number,
    description: PropTypes.string,
    cta: PropTypes.object,
};
export default ScrollHijackGroupTextBlockItem;
