import React from 'react';
import PropTypes from 'prop-types';
import style from './ScrollHijackGroupStatementsAndStats.module.scss';

const ScrollHijackGroupStatementsAndStats = ({ index, statements, title, stats }) => {
    const statsLength = stats.length;
    const statementsLength = statements.length;
    return statements && (
        <div key={index} className={style.fourColsInfo}>
            <div className={[style.container, 'container', 'details'].join(' ')}>
                <div className={style.contentStats}>
                    {title && <h2 className={style.titleStats}>{title}</h2>}
                    <div className={style.itemsStats}>
                        {statements &&
                                statements.map((item, i) => {
                                    return (
                                        <div className={[style.itemStats, !item.text ? style.itemStatsWithoutDesc : '', statementsLength === 1 ? style.singleStatement : ''].join(' ')} key={i}>
                                            <img
                                                className={[!item.text ? style.iconImageStatsWithoutDesc : style.iconImageStats].join(' ')}
                                                src={item.image.url}
                                                alt={item.image.alt}
                                            />
                                            <div
                                                className={style.descStats}
                                                dangerouslySetInnerHTML={{
                                                    __html: item.text,
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                    </div>
                    <div
                        className={style.stats}
                        style={
                            statements && statements.length && stats.length >= 1
                                ? {
                                    borderTop: '1px solid #C9C9D7',
                                }
                                : {}
                        }
                    >
                        {stats &&
                                stats.map((stats, id) => (
                                    <div className={[style.statsInfo, statsLength === 1 ? style.statSingle : ''].join(' ')} key={id}>
                                        <div className={['h1', style.statsTitle].join(' ')}>
                                            {stats.title}
                                        </div>
                                        <div
                                            className={style.statsDesc}
                                            dangerouslySetInnerHTML={{
                                                __html: stats.description,
                                            }}
                                        />
                                    </div>
                                ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

ScrollHijackGroupStatementsAndStats.propTypes = {
    index: PropTypes.number,
    title: PropTypes.string,
    statements: PropTypes.array,
    stats: PropTypes.array
};
export default ScrollHijackGroupStatementsAndStats;
