import React, { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import style from './SearchResult.module.scss';
import { SmartLink } from '../SmartLink';
import Pagination from '../Pagination';

import { search } from '../../redux/search/search.action';
import {
    searchGetLoading,
    searchGetResult,
    // searchGetSuccess,
} from '../../redux/search/search.selector';

const SearchResult = props => {
    const { query } = useRouter();
    const { title, resultLabel } = props;
    const keyword = useRef(query.keyword);
    const dispatch = useDispatch();
    const state = useSelector(s => s);
    const loading = searchGetLoading(state);
    const searchResults = searchGetResult(state);
    // const success = searchGetSuccess(state);

    const [types, setTypes] = useState('all');
    // const [page, setPage] = useState(0);
    const [dataCache, setDataCache] = useState(null);
    const [filterCache, setFilterCache] = useState(null);

    const handlePageChange = value => {
        // setPage(value);
        dispatch(search(keyword.current, types, value));
    };

    const handleTypeChange = value => {
        if (value === 'all') {
            setTypes(value);
            // setPage(0);
            dispatch(search(keyword.current, '', 0));
        } else {
            setTypes(value);
            // setPage(0);
            dispatch(search(keyword.current, value, 0));
        }
    };

    useEffect(() => {
        dispatch(search(keyword.current, types, 0));
    }, []);

    useEffect(() => {
        if (!loading && searchResults?.data !== undefined) {
            setDataCache(searchResults.data);
            setFilterCache(searchResults.filters);
        }
    }, [loading]);

    return (
        <div className={style.el}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.searchHeader}>
                    <h2>
                        {title} <q>{keyword.current}</q>
                    </h2>
                </div>
                {/* tab components */}
                <div className={style.filterTabsWrapper}>
                    {filterCache &&
                        filterCache?.map(({ title, value, numberOfItems }, id) => (
                            <div
                                data-value={value}
                                key={id}
                                className={[style.filterTabs, types === value ? style.isActive : null].join(' ')}
                                onClick={() => handleTypeChange(value)}
                            >
                                <span>{title}</span>
                                <span data-items={numberOfItems}>({numberOfItems})</span>
                            </div>
                        ))}
                </div>

                {/*  total  number of results */}
                <div className={style.totalResults}>
                    {' '}
                    <div>
                        {' '}
                        <span>
                            {' '}
                            <u>{searchResults?.data?.totalItems}</u>
                        </span>{' '}
                        {resultLabel}
                    </div>{' '}
                </div>

                {/* results */}
                <div className={style.resultsWrapper}>
                    {dataCache &&
                        dataCache?.results.map(({ title, cta, type, date }, id) => (
                            <div className={style.content} key={id}>
                                <div className={style.tag}>
                                    <div className={style.tagBox}>{type}</div>
                                    <div className={style.date}>{date}</div>
                                </div>
                                <div className={style.title}>
                                    <h4>{title}</h4>
                                </div>
                                <div className={style.cta}>
                                    <SmartLink href={cta?.link.url} hasArrow className='arrow-link'>
                                        {cta?.text}
                                    </SmartLink>
                                </div>
                            </div>
                        ))}
                </div>
                <div className={style.paginationWrapper}>
                    <Pagination
                        total={dataCache?.totalPages}
                        active={dataCache?.currentPage}
                        changePage={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
};

SearchResult.propTypes = {
    // filters: PropTypes.array,
    // data: PropTypes.shape({
    //     currentPage: PropTypes.number,
    //     totalPages: PropTypes.number,
    //     totalItems: PropTypes.number,
    //     itemsPerPage: PropTypes.number,
    //     // results: PropTypes.shape({
    //     //     title: PropTypes.string,
    //     //     value: PropTypes.string,
    //     //     link: PropTypes.shape({
    //     //         target: PropTypes.string,
    //     //         url: PropTypes.string,
    //     //     }),
    //     //     type: PropTypes.string,
    //     //     date: PropTypes.string,
    //     // }),
    //     results: PropTypes.array,
    // }),
    title: PropTypes.string,
    resultLabel: PropTypes.string,
    totalPages: PropTypes.number,
    customElements: PropTypes.number,
};

SearchResult.defaultProps = {
    filters: [
        // {
        //     title: 'All',
        //     value: 'all',
        //     numberOfItems: 30,
        // },
        // {
        //     title: 'People',
        //     value: 'people',
        //     numberOfItems: 2,
        // },
        // {
        //     title: 'Portfolio',
        //     value: 'portfolio',
        //     numberOfItems: 13,
        // },
        // {
        //     title: 'Investing',
        //     value: 'investing',
        //     numberOfItems: 1,
        // },
        // {
        //     title: 'Responsibility',
        //     value: 'responsibility',
        //     numberOfItems: 0,
        // },
        // {
        //     title: 'About',
        //     value: 'about',
        //     numberOfItems: 2,
        // },
        // {
        //     title: 'Media',
        //     value: 'media',
        //     numberOfItems: 4,
        // },
    ],
    data: {
        currentPage: 0,
        totalPages: 10,
        totalItems: 100,
        itemsPerPage: 10,
        results: [
            // {
            //     title:
            //         'A global team bound by a distinct culture. Our collaborative and entrepreneurial culture unites a diverse team of 360+ individuals across 15 offices around the world.',
            //     link: {
            //         target: '',
            //         url: '/en',
            //         text: 'Read More',
            //     },
            //     type: 'people',
            //     date: '12 Nov 2021',
            // },
            // {
            //     title:
            //         'What does it take to build lasting value? Learn more about how ESG guides our approach to sustainable growth.',
            //     link: {
            //         target: '',
            //         url: '/en',
            //         text: 'Read More',
            //     },
            //     type: 'Views',
            //     date: '12 Nov 2021',
            // },
            // {
            //     title:
            //         'A global team bound by a distinct culture. Our collaborative and entrepreneurial culture unites a diverse team of 360+ individuals across 15 offices around the world.',
            //     link: {
            //         target: '',
            //         url: '/en',
            //         text: 'Read More',
            //     },
            //     type: 'Portfolio',
            //     date: '12 Nov 2021',
            // },
            // {
            //     title:
            //         'A global team bound by a distinct culture. Our collaborative and entrepreneurial culture unites a diverse team of 360+ individuals across 15 offices around the world.',
            //     link: {
            //         target: '',
            //         url: '/en',
            //         text: 'Read More',
            //     },
            //     type: 'people',
            //     date: '12 Nov 2021',
            // },
            // {
            //     title:
            //         'What does it take to build lasting value? Learn more about how ESG guides our approach to sustainable growth.',
            //     link: {
            //         target: '',
            //         url: '/en',
            //         text: 'Read More',
            //     },
            //     type: 'Views',
            //     date: '12 Nov 2021',
            // },
            // {
            //     title:
            //         'A global team bound by a distinct culture. Our collaborative and entrepreneurial culture unites a diverse team of 360+ individuals across 15 offices around the world.',
            //     link: {
            //         target: '',
            //         url: '/en',
            //         text: 'Read More',
            //     },
            //     type: 'Portfolio',
            //     date: '12 Nov 2021',
            // },
            // {
            //     title:
            //         'A global team bound by a distinct culture. Our collaborative and entrepreneurial culture unites a diverse team of 360+ individuals across 15 offices around the world.',
            //     link: {
            //         target: '',
            //         url: '/en',
            //         text: 'Read More',
            //     },
            //     type: 'people',
            //     date: '12 Nov 2021',
            // },
            // {
            //     title:
            //         'What does it take to build lasting value? Learn more about how ESG guides our approach to sustainable growth.',
            //     link: {
            //         target: '',
            //         url: '/en',
            //         text: 'Read More',
            //     },
            //     type: 'Views',
            //     date: '12 Nov 2021',
            // },
            // {
            //     title:
            //         'A global team bound by a distinct culture. Our collaborative and entrepreneurial culture unites a diverse team of 360+ individuals across 15 offices around the world.',
            //     link: {
            //         target: '',
            //         url: '/en',
            //         text: 'Read More',
            //     },
            //     type: 'Portfolio',
            //     date: '12 Nov 2021',
            // },
            // {
            //     title:
            //         'A global team bound by a distinct culture. Our collaborative and entrepreneurial culture unites a diverse team of 360+ individuals across 15 offices around the world.',
            //     link: {
            //         target: '',
            //         url: '/en',
            //         text: 'Read More',
            //     },
            //     type: 'Portfolio',
            //     date: '12 Nov 2021',
            // },
            // {
            //     title:
            //         'A global team bound by a distinct culture. Our collaborative and entrepreneurial culture unites a diverse team of 360+ individuals across 15 offices around the world.',
            //     link: {
            //         target: '',
            //         url: '/en',
            //         text: 'Read More',
            //     },
            //     type: 'media',
            //     date: '12 Nov 2021',
            // },
        ],
    },
};

export default SearchResult;
