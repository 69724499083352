import React from 'react';

import PropTypes from 'prop-types';

import Disclaimer from '../Disclaimer';

import style from './DisclaimerContainer.module.scss';

const DisclaimerContainer = ({ disclaimers }) => {
    return (
        <div className={style.disclaimerContainer}>
            {disclaimers.map((disclaimer, index) => {
                return <Disclaimer key={index} {...disclaimer} />;
            })}
        </div>
    );
};

DisclaimerContainer.propTypes = {
    disclaimers: PropTypes.array,
};

export default DisclaimerContainer;
