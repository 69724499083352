import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useRouter } from 'next/router';

import style from './NewsDetail.module.scss';

import { useMediaQuery } from '@react-hook/media-query';
import { SmartLink } from '../SmartLink';
import Player from '../Player';

const ContactCard = ({ title, name, role, phone, email }) => {
    return (
        <div className={style.contact}>
            <div className={style['contact-title']}>{title}</div>
            {name.value && (
                <div className={style['contact-item']}>
                    <div className={style['contact-item-title']}>{name.title}</div>
                    <div className={style['contact-item-value']}>{name.value}</div>
                </div>
            )}
            {role.value && (
                <div className={style['contact-item']}>
                    <div className={style['contact-item-title']}>{role.title}</div>
                    <div className={style['contact-item-value']}>{role.value}</div>
                </div>
            )}
            {phone.value && (
                <div className={style['contact-item']}>
                    <div className={style['contact-item-title']}>{phone.title}</div>
                    <div className={style['contact-item-value']}>
                        <a href={`tel:${phone.value}`} rel="noopener noreferrer">
                            {phone.value}
                        </a>
                    </div>
                </div>
            )}
            {email.value && (
                <div className={style['contact-item']}>
                    <div className={style['contact-item-title']}>{email.title}</div>
                    <div className={style['contact-item-value']}>
                        {email.value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? (
                            <a href={`mailto:${email.value}`} rel="noopener noreferrer">
                                {email.value}
                            </a>
                        ) : (
                            <>{email.value}</>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

ContactCard.propTypes = {
    title: PropTypes.string,
    name: PropTypes.object,
    role: PropTypes.object,
    phone: PropTypes.object,
    email: PropTypes.object,
};

const DownloadCard = ({ image, mobileImage, title, description, cta }) => {
    const isMobile = useMediaQuery('only screen and (max-width: 650px)');
    return (
        <div className={style.download}>
            <div className={`${style.treasersCard} ${image.url ? '' : style.noImage}`}>
                {image.url && (
                    <div className={style.treaserImageBox}>
                        <img src={isMobile ? mobileImage.url : image.url} alt={image.alt} />
                    </div>
                )}
                <div className={style.treaserCopy}>
                    <div className={style.treaserTitle}>
                        <p>{title}</p>
                    </div>
                    <div className={style.treaserDescription}>
                        <h3>{description}</h3>
                    </div>
                    <div className={style.treaserCta}>
                        <SmartLink href={cta.link.url}>{cta.text}</SmartLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

DownloadCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.object,
    image: PropTypes.object,
    mobileImage: PropTypes.object,
};

const NewsDetail = (props) => {
    const { header, content } = props;
    const [currentUrl, getCurrentUrl] = useState(0);
    // const router = useRouter();

    useEffect(() => {
        getCurrentUrl(window.location.href);
        if (window.__sharethis__) window.__sharethis__.initialize();
    }, []);

    const TextFormat = (content) => {
        return (
            <div className={style['article-text']}>
                <h4>{content.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: content.body }} />
            </div>
        );
    };

    const ImageFormat = (content) => {
        return (
            <div className={style['article-image']}>
                <img src={content.image.url} alt={content.image.alt} />
            </div>
        );
    };

    return (
        <>
            <div className={style.el}>
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.header}>
                        <div className={style.headerWrapper}>
                            <div className={style['header-date']}>{header.date}</div>
                            <div className={style['header-author']}>
                                {header.photo?.url && (
                                    <div className={style['header-photo']}>
                                        <img src={header.photo.url} />
                                    </div>
                                )}
                                {header.name && <div className={style['header-name']}>{`by ${header.name}`}</div>}
                            </div>
                            {/* <div
                                className={style.close}
                                onClick={() => router.back()}
                            >
                                <span />
                                <span />
                            </div> */}
                        </div>
                        <div className={style.headerWrapper}>
                            <div className={style['header-share-title']}>{header.share.title}</div>
                            <div className={style['header-share-buttons']}>
                                <ul className={style.social}>
                                    {header.share &&
                                        header.share?.items.map((item, idx) => (
                                            <li
                                                key={idx}
                                                className={[
                                                    style['social-item'],
                                                    'st-custom-button',
                                                    `icon-${item.icon}`,
                                                ].join(' ')}
                                                data-network={item.title}
                                                data-url={currentUrl}
                                                data-title={content.headline}
                                                data-image="/images/image.jpg"
                                            />
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={style.content}>
                        <div className={style.contentWrapper}>
                            <h1 className={style.headline}>{content.headline}</h1>
                            {content.quote && (
                                <div className={style.quote}>
                                    <div className={style['quote-title']}>{content.quote?.quote}</div>
                                    {content.quote.author && (
                                        <div className={style['quote-author']}>{content.quote?.author}</div>
                                    )}
                                    {content?.quote?.cta?.text && (
                                        <button
                                            className={[style['quote-button'], 'st-custom-button'].join(' ')}
                                            data-network="linkedin"
                                            data-url={currentUrl}
                                            data-title={content.headline}
                                            data-image="/images/image.jpg"
                                        >
                                            <span className="icon-linkedin2" />
                                            {content.quote.cta.text}
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={style.contentWrapper}>
                            {content.video && (
                                <div className={style.video}>
                                    <Player poster={content.video.coverImage?.url} link={content.video?.video} />
                                </div>
                            )}
                            <div className={style.article}>
                                {content?.article?.map((item, i) => {
                                    return item.type === 'newsDetailText' || item.type === 'caseStudyText' ? (
                                        <TextFormat key={i} {...item} />
                                    ) : item.type === 'newsDetailImage' || item.type === 'caseStudyImage' ? (
                                        <ImageFormat key={i} {...item} />
                                    ) : null;
                                })}
                            </div>
                            {content.download?.slice(0, 4).map((item, i) => (
                                <DownloadCard {...item} key={`download-${i}`} />
                            ))}
                            {content.contact?.slice(0, 3).map((item, i) => (
                                <ContactCard {...item} key={`contact-${i}`} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

NewsDetail.propTypes = {
    header: PropTypes.object,
    content: PropTypes.object,
};
export default NewsDetail;

NewsDetail.defaultProps = {};
