import React from 'react';
import PropTypes from 'prop-types';

import { SmartLink } from '../SmartLink';

import style from './SponsorList.module.scss';

const SponsorList = props => {
    const { title, items } = props;

    return (
        <div className={style.el}>
            <div className={[style.container, 'container'].join(' ')}>
                <h2 className={style.headline}>{title}</h2>
                <div className={style.sponsors}>
                    <div className={style.wrapper}>
                        {items.map((item, i) => (
                            <SmartLink key={i} href={item.link.url} className={style.sponsorItem}>
                                <img src={item.icon.url} alt={item.icon.alt} />
                            </SmartLink>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

SponsorList.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
};

export default SponsorList;
