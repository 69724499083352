import React from 'react';

// components
import Banner from './Banner';
import CommonStaticHeader from './CommonStaticHeader';
import HomeTeasers from './HomeTeasers';
import LatestNews from './LatestNews';
import JumpOffCards from './JumpOffCards';
import RelatedNews from './RelatedNews';
import InnerPageHeader from './InnerPageHeader';
import PeopleListing from './PeopleListing';
import Breadcrumb from './Breadcrumb';
import TwoColsMedia from './TwoColsMedia';
import ThreeCardsCarouselPopup from './ThreeCardsCarouselPopup';
import FourColsInfo from './FourColsInfo';
import FeatureArticle from './FeatureArticle';
import NewsListing from './NewsListing';
import NewsDetail from './NewsDetail';
import ProfileDetail from './ProfileDetail';
import TwoColsDetailsBox from './TwoColsDetailsBox';
import InvestmentDetails from './InvestmentDetails';
import PeopleList from './PeopleList';
import RecentInvestment from './RecentInvestment';
import OfficesTabs from './OfficesTabs';
import ScrollHijackGroup from './ScrollHijackGroup';
import OfficeDetail from './OfficeDetail';
import PortfolioListing from './Portfolio';
import InvestmentLifecycle from './InvestmentLifecycle';
import InvResReport from './InvResReport';
import CaseStudyScrollHijack from './CaseStudyScrollHijack';
import ReportListing from './ReportListing';
import CharityTeaser from './CharityTeaser';
import CaseStudyListing from './CaseStudyListing';
import TextOnlyHeader from './TextOnlyHeader';
import SponsorList from './SponsorList';
import StaticPageContent from './StaticPageContent';
import SearchResult from './SearchResult';
import Testimonials from './Testimonials';
import DisclaimerContainer from './DisclaimerContainer';

import {
    // reformatHeroBanner,
    reformatTwoColsMedia,
    reformatThreeCardsCarouselPopup,
    reformatNewsDetail,
    reformatStatementsAndStats,
    reformatCaseStudyDetail,
    reformatFeatureESGReport,
    reformatFeatureCaseStudy,
} from '../utils/formatData';
import Disclaimer from './Disclaimer';
import TwoColumnBanner from './TwoColumnBanner';

export const render = (component, i, components, isPopup, isMobile, googleMapKey) => {
    const key = i;
    let props;
    let Component = null;
    component.popup = isPopup;

    switch (component.type) {
        case 'banner':
            props = {
                ...component,
            };
            Component = Banner;
            break;
        case 'pageBottomBanner':
            props = {
                ...component,
                diagonal: true,
            };
            Component = TwoColumnBanner;
            break;
        case 'pageBanner':
            props = {
                ...component,
            };
            Component = TwoColumnBanner;
            break;
        case 'landingPageHeroBanner':
            props = {
                ...component,
            };
            Component = CommonStaticHeader;
            break;
        case 'landingPageTeasersBlock':
        case 'pageContentTeasersBlock':
        case 'homepageTeasersBlock':
        case 'homepageTeasers':
            props = {
                ...component,
            };
            Component = HomeTeasers;
            break;
        case 'newsTeaser':
            props = {
                ...component,
            };
            Component = LatestNews;
            break;
        case 'heroSection':
            props = {
                ...component,
            };
            Component = InnerPageHeader;
            break;
        case 'pageTeaserGroup':
            props = {
                ...component,
            };
            Component = JumpOffCards;
            break;
        case 'esgReportList':
            props = {
                ...component,
                cardType: 'report',
            };
            Component = RelatedNews;
            break;
        case 'caseStudyTeaserList':
            props = {
                ...component,
                cardType: 'caseStudy',
            };
            Component = RelatedNews;
            break;
        case 'relatedNewsViewsBox':
            props = {
                ...component,
            };
            Component = RelatedNews;
            break;
        case 'peopleListing':
            props = {
                ...component,
                isMobile: isMobile,
            };
            Component = PeopleListing;
            break;
        case 'breadcrumb':
            props = {
                ...component,
            };
            Component = Breadcrumb;
            break;
        case 'contentBlocks':
            props = {
                ...reformatTwoColsMedia(component.data),
            };
            Component = TwoColsMedia;
            break;
        case 'testimonials':
            props = {
                ...component,
            };
            Component = Testimonials;
            break;
        case 'peopleValues':
            props = {
                ...reformatThreeCardsCarouselPopup(component.data),
            };
            Component = ThreeCardsCarouselPopup;
            break;
        case 'statementsAndStats':
            props = {
                ...reformatStatementsAndStats(component),
            };
            Component = FourColsInfo;
            break;
        case 'ourValues':
            props = {
                ...component.data,
            };
            Component = FourColsInfo;
            break;
        case 'profileDetail':
            props = {
                ...component,
            };
            Component = ProfileDetail;
            break;
        case 'featureCaseStudy':
            props = {
                ...reformatFeatureCaseStudy(component),
            };
            Component = FeatureArticle;
            break;
        case 'featureESGReport':
            props = {
                ...reformatFeatureESGReport(component),
            };
            Component = FeatureArticle;
            break;
        case 'featureArticle':
            props = {
                ...component,
            };
            Component = FeatureArticle;
            break;
        case 'newsListing':
            props = {
                ...component,
                isMobile: isMobile,
            };
            Component = NewsListing;
            break;
        case 'newsDetailInfo':
            props = {
                ...reformatNewsDetail(components),
            };
            Component = NewsDetail;
            break;
        case 'caseStudyInfo':
            props = {
                ...reformatCaseStudyDetail(components),
            };
            Component = NewsDetail;
            break;
        case 'textBlockItem':
        case 'InvestingCreditContentBlocks':
            props = {
                ...component,
            };
            Component = TwoColsDetailsBox;
            break;
        case 'investingCreditDetail':
            props = {
                ...component,
            };
            Component = InvestmentDetails;
            break;
        case 'peopleList':
            props = {
                ...component,
            };
            Component = PeopleList;
            break;
        case 'recentInvestments':
            props = {
                ...component,
            };
            Component = RecentInvestment;
            break;
        case 'contactUs':
            props = {
                ...component,
            };
            Component = OfficesTabs;
            break;
        case 'scrollHijackGroup':
            props = {
                ...component,
            };
            Component = ScrollHijackGroup;
            break;
        case 'officeDetail':
            props = {
                ...component,
                googleMapKey,
            };
            Component = OfficeDetail;
            break;
        case 'PortfolioListing':
            props = {
                ...component,
                isMobile: isMobile,
            };
            Component = PortfolioListing;
            break;
        case 'circlesBlock':
        case 'investmentLifecycle':
            props = {
                ...component,
            };
            Component = InvestmentLifecycle;
            break;
        case 'investmentResponsibilityText':
        case 'investmentResponsibilityReport':
        case 'downloadReport':
            props = {
                ...component,
            };
            Component = InvResReport;
            break;
        case 'portfolioBasicInfo':
            props = {
                ...component,
            };
            Component = CaseStudyScrollHijack;
            break;
        case 'reportListing':
            props = {
                ...component,
                isMobile: isMobile,
            };
            Component = ReportListing;
            break;
        case 'charityTeaser':
            props = {
                ...component,
            };
            Component = CharityTeaser;
            break;
        case 'CaseStudyListing':
            props = {
                ...component,
                isMobile: isMobile,
            };
            Component = CaseStudyListing;
            break;
        case 'header':
            props = {
                ...component,
            };
            Component = TextOnlyHeader;
            break;
        case 'sponsorList':
            props = {
                ...component,
            };
            Component = SponsorList;
            break;
        case 'staticPageContent':
            props = {
                ...component,
            };
            Component = StaticPageContent;
            break;
        case 'searchResult':
            props = {
                ...component,
            };
            Component = SearchResult;
            break;
        case 'disclaimerItem':
            props = {
                ...component,
            };
            Component = Disclaimer;
            break;
        case 'disclaimerContainer':
            props = {
                ...component,
            };
            Component = DisclaimerContainer;
            break;
        default:
    }

    if (Component) {
        return <Component key={key} {...props} />;
    }
    return null;
};
