import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import style from './TeaserCard.module.scss';

const TeaserCard = ({ image, title, isOdd, mobileImage, description, disclaimerText, theme, cta, isMobile, index }) => {
    const [cardStyle, setCardStyle] = useState('');

    useEffect(() => {
        setCardStyle('');
        if (theme === 'Orange') {
            setCardStyle([style.teaserCardOrange, style.teaserCardAlt].join(' '));
        } else if (theme === 'Default') {
            setCardStyle(style.teaserCardAlt);
        } else if (theme === 'Large') {
            setCardStyle(style.teaserCardLarge);
        }
    }, [theme]);

    const content = (
        <>
            <div className={style.teaserImageBox}>
                <img src={isMobile && mobileImage.url ? mobileImage.url : image.url} alt={image.alt} />
            </div>
            <div className={style.teaserCopy}>
                <div className={style.teaserTitle}>
                    <h3>{title}</h3>
                </div>
                <div className={style.teaserDescription}
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}>
                </div>
                {disclaimerText !== '' && disclaimerText !== null && (
                    <div className={style.disclaimer} dangerouslySetInnerHTML={{ __html: disclaimerText }}></div>
                )}
                <div className={style.teaserCta}>
                    <a href={cta.link.url} target="_self">
                        {cta.text}
                    </a>
                </div>
            </div>
        </>
    );

    return theme === 'Large' ? (
        <article id={`teaser-card-${index}`} className={`${style.teaserCard} ${cardStyle} ${isOdd ? style.oddLayout : ''}`}>
            {content}
        </article>
    ) : (
        <div id={`teaser-card-${index}`} className={`${style.teaserCard} ${cardStyle} ${isOdd ? style.oddLayout : ''}`}>
            {content}
        </div>
    );
};

export default TeaserCard;

TeaserCard.propTypes = {
    id: PropTypes.string,
    image: PropTypes.object,
    title: PropTypes.string,
    mobileImage: PropTypes.object,
    description: PropTypes.string,
    disclaimerText: PropTypes.string,
    cta: PropTypes.object,
    index: PropTypes.number,
    theme: PropTypes.string,
    isMobile: PropTypes.bool,
    isOdd: PropTypes.bool,
    itemsLength: PropTypes.number,
};
